import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import PatternPicker from './components/PatternPicker'
import ColorPicker from './components/ColorPicker'
import SizePicker from './components/SizePicker'
import CarpetPreview from './components/CarpetPreview'
import Form from './components/Form'

const CarpetConfigurator = (props) => {
  const { userid } = props

  const [width, setWidth] = useState(126)
  const [height, setHeight] = useState(238)
  const [tileWidth] = useState(7)
  const [tileHeight] = useState(7)
  const [activeRow, setActiveRow] = useState()
  const [activePattern, setActivePattern] = useState('Halbraute')
  const [activeColor1, setActiveColor1] = useState()
  const [activeColor2, setActiveColor2] = useState()
  const [colors, setColors] = useState({})
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [mail, setMail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const handleWidthChange = (name, value) => {
    const newWidth = parseInt(value, 10)
    setWidth(newWidth)
  }

  const handleHeightChange = (name, value) => {
    const newHeight = parseInt(value, 10)
    setHeight(newHeight)
  }

  const handleRowSelection = (row) => {
    setActiveRow(row)
  }

  const handlePatternChange = (pattern) => {
    setActivePattern(pattern)
  }

  const handleColorChange = (color, type) => {
    if (type === 'color1') {
      setActiveColor1(color)
    } else if (type === 'color2') {
      setActiveColor2(color)
    }
    if (activeRow) {
      const newColors = { ...colors }
      newColors[activeRow] = { ...newColors[activeRow], row: activeRow, [type]: color }
      setColors(newColors)
    }
  }

  const onFillAllButtonClick = () => {
    const rows = Math.floor(height / tileHeight)
    const newColors = {}

    if (activeColor1 && !activeColor2) {
      for (let step = 0; step < rows; step += 1) {
        const rowNumber = step + 1
        const topId = `${rowNumber}-top`
        const bottomId = `${rowNumber}-bottom`
        newColors[topId] = { row: topId, color1: activeColor1, color2: '' }
        newColors[bottomId] = { row: bottomId, color1: activeColor1, color2: '' }
      }
    }

    if (activeColor2 && !activeColor1) {
      for (let step = 0; step < rows; step += 1) {
        const rowNumber = step + 1
        const topId = `${rowNumber}-top`
        const bottomId = `${rowNumber}-bottom`
        newColors[topId] = { row: topId, color1: '', color2: activeColor2 }
        newColors[bottomId] = { row: bottomId, color1: '', color2: activeColor2 }
      }
    }

    if (activeColor1 && activeColor2) {
      for (let step = 0; step < rows; step += 1) {
        const rowNumber = step + 1
        const topId = `${rowNumber}-top`
        const bottomId = `${rowNumber}-bottom`
        newColors[topId] = { row: topId, color1: activeColor1, color2: activeColor2 }
        newColors[bottomId] = { row: bottomId, color1: activeColor1, color2: activeColor2 }
      }
    }

    setColors(newColors)
  }

  const onResetButtonClick = () => {
    setColors({})
  }

  const onDownloadAsPDF = async () => {
    const data = {
      width,
      height,
      pattern: activePattern,
      colors
    }

    const response = await fetch('/wp-json/tessanda/create-carpet-pdf/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const savePDF = await response.json()
    if (savePDF.success === true) {
      if (savePDF.url) {
        window.open(savePDF.url, '_blank')
      }
    } else {
      console.error('Fehler beim Erstellen des PDFs')
    }
  }

  const onOpenForm = () => {
    setFormOpen(true)
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const data = {
      firstname,
      lastname,
      phone,
      mail,
      message,
      width,
      height,
      pattern: activePattern,
      colors
    }

    const response = await fetch('/wp-json/tessanda/send-carpet-mail/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const sendMail = await response.json()
    console.log(sendMail)
    if (sendMail.success === true) {
      console.log(sendMail)
    } else {
      console.error('Fehler beim Versenden des Mails')
    }
  }

  const onSaveInAccount = async () => {
    const data = {
      user: userid,
      width,
      height,
      pattern: activePattern,
      colors
    }
    const response = await fetch('/wp-json/tessanda/save-carpet/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const saveCarpet = await response.json()
    if (saveCarpet.success === true) {
      setSaveSuccess(true)
    } else {
      console.error('Fehler beim Speichern des Teppichs')
    }
  }

  const loadCarpet = async () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const urlCarpet = urlParams.get('loadCarpet')

    if (urlCarpet) {
      fetch(`/wp-json/wp/v2/carpet/${urlCarpet}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.acf.colors) {
            const loadedColors = JSON.parse(data.acf.colors)
            setColors(loadedColors)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  useEffect(() => {
    loadCarpet()
  }, [])

  return (
    <>
      {userid === '0' && (
        <div className='login-hint'>
          <a href='/kundenkonto'>Melden Sie sich an</a>, um Ihren Teppich in Ihrem Kundenkonto zu
          speichern und zu einem späteren Zeitpunkt zu bearbeiten.
        </div>
      )}
      <div className='carpet-preview'>
        <CarpetPreview
          width={width}
          height={height}
          tileWidth={tileWidth}
          tileHeight={tileHeight}
          pattern={activePattern}
          colors={colors}
          handleRowSelection={handleRowSelection}
        />
      </div>
      <div className='carpet-controls'>
        <PatternPicker activePattern={activePattern} handlePatternChange={handlePatternChange} />
        <SizePicker
          width={width}
          height={height}
          tileWidth={tileWidth}
          tileHeight={tileHeight}
          handleWidthChange={handleWidthChange}
          handleHeightChange={handleHeightChange}
        />
        <ColorPicker
          type='color1'
          activeColor={activeColor1}
          title='Farbe 1'
          handleColorChange={handleColorChange}
        />
        <ColorPicker
          type='color2'
          activeColor={activeColor2}
          title='Farbe 2'
          handleColorChange={handleColorChange}
        />
        <button
          type='button'
          className='carpetconfigurator-button button--fillall'
          disabled={!activeColor1 && !activeColor2}
          onClick={() => onFillAllButtonClick()}>
          Ganzen Teppich füllen
        </button>
        <button
          type='button'
          className='carpetconfigurator-button button--reset'
          onClick={() => onResetButtonClick()}>
          Alle Farben zurücksetzen
        </button>
      </div>
      <div className='carpet-save-options'>
        <h5>Ihre nächsten Schritte</h5>
        <button
          type='button'
          className='carpetconfigurator-button button--download'
          onClick={() => onDownloadAsPDF()}>
          Als PDF herunterladen
        </button>
        <button
          type='button'
          className='carpetconfigurator-button button--submit'
          onClick={() => onOpenForm()}>
          Offerte anfordern
        </button>
        {userid && userid !== '0' && (
          <button
            type='button'
            className='carpetconfigurator-button button--save'
            onClick={() => onSaveInAccount()}>
            Im Kundenkonto speichern
          </button>
        )}
        {saveSuccess && (
          <p className='save-success-message'>
            Erfolgreich im <a href='/kundenkonto'>Kundenkonto</a> gespeichert.
          </p>
        )}
      </div>
      {formOpen && (
        <Form
          handleFormSubmit={handleFormSubmit}
          setFirstname={setFirstname}
          setLastname={setLastname}
          setMail={setMail}
          setPhone={setPhone}
          setMessage={setMessage}
        />
      )}
    </>
  )
}

CarpetConfigurator.propTypes = {
  userid: PropTypes.string.isRequired
}

const domContainer = document.getElementById('carpetConfigurator')
if (domContainer) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  ReactDOM.render(<CarpetConfigurator {...domContainer.dataset} />, domContainer)
}
