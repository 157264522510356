import jQuery from 'jquery'
import { CountUp } from 'countup.js'
import Swiper, { Navigation } from 'swiper'

document.addEventListener('DOMContentLoaded', () => {
  // SET EVENT DETAILS TO EVENT FORM
  //----------------------------------------------------------
  jQuery(document).on('nfFormReady', () => {
    // Select first event
    if (jQuery('#eventSelector').length) {
      jQuery('#eventSelector input:not(:disabled):radio:first').trigger('click')
    }
    // Selected date and time
    if (jQuery('#eventSelector').length) {
      jQuery('body').on('click', '.event-radio', function clickEventRadio() {
        // ID
        const id = jQuery(this).data('eventid')
        jQuery('#nf-field-12').val(id).trigger('change')
        // Date
        const date = jQuery(this).data('eventdate')
        jQuery('#nf-field-14').val(date).trigger('change')
        // Time
        const time = jQuery(this).data('eventtime')
        jQuery('#nf-field-15').val(time).trigger('change')
      })
    }
  })

  jQuery(document).on('nfFormSubmitResponse', () => {
    jQuery('#eventSelector').hide()
  })

  // IMAGE SLIDESHOW
  //----------------------------------------------------------

  /* eslint-disable no-unused-vars */
  Swiper.use([Navigation])
  const swiper = new Swiper('.swiper-container', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    autoHeight: true,
    observer: true,
    observeParents: true,
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })
  /* eslint-enable no-unused-vars */

  // OPEN MINI CART
  // ----------------------------------------------------------
  jQuery('.cartButton').on('click', function clickCartButton() {
    if (jQuery(this).hasClass('active')) {
      jQuery(this).removeClass('active')
      jQuery('.miniCart').removeClass('active')
    } else {
      jQuery(this).addClass('active')
      jQuery('.miniCart').addClass('active')
    }
  })

  jQuery('.cartButtonWrapper').on('mouseover', function mouseoverCartButtonWrapper(e) {
    jQuery(this).find('.cartButton').addClass('active')
    jQuery('.miniCart').addClass('active')
    e.preventDefault()
  })

  jQuery('.cartButtonWrapper').on('mouseleave', function mouseleaveCartButtonWrapper(e) {
    e.preventDefault()
    jQuery(this).find('.cartButton').removeClass('active')
    jQuery('.miniCart').removeClass('active')
  })

  // Quantity functionality in cart
  //----------------------------------------------------------
  jQuery(document).on('click', '.minus,.plus', function clickMinusPlus() {
    const $cartOverview = jQuery('#cart-overview')
    const $cartItem = jQuery(this).parents('.cart-item')
    const $itemCount = $cartItem.find('.item-count')
    const $itemPrice = $cartItem.find('.item-price').find('span')
    const $itemSubtotal = $cartItem.find('.item-subtotal').find('span')
    // const variation = $cartItem.data('variation')
    // const attribute = $cartItem.data('attribute')
    const referer = $cartOverview.find('[name="_wp_http_referer"]').val()
    const nonce = $cartOverview.find('[name="_wpnonce"]').val()
    const key = $cartItem.data('key')
    let quantity = parseFloat($itemCount.text())
    const buttonClass = jQuery(this).attr('class')
    const $count = jQuery('#cartCount')
    const cartCount = parseInt($count.attr('data-count'), 10)
    const $miniCartItem = jQuery(`.woocommerce-mini-cart-item[data-key=${key}]`)
    const $miniItemCount = $miniCartItem.find('.item-quantity')
    let newCount

    if (buttonClass === 'minus') {
      if (quantity === 1) {
        $cartItem.remove()
        $miniCartItem.remove()
      }
      quantity -= 1
      newCount = cartCount - 1
    } else {
      quantity += 1
      newCount = cartCount + 1
    }

    if (newCount > 99 || Number.isNaN(newCount)) {
      newCount = '+'
    }

    if (newCount < 0) {
      newCount = 0
    }

    $count.attr('data-count', newCount)
    jQuery('#cartCount').html(newCount)

    if (quantity > 0) {
      $itemCount.text(quantity)
      $miniItemCount.text(quantity)

      const newPrice = parseFloat(quantity * parseFloat($itemPrice.text().replace(',', '')))
      const countUp = new CountUp($itemSubtotal.attr('id'), newPrice)
      countUp.start()
      $itemSubtotal.attr('data-price', newPrice)
    }

    const data = {
      update_cart: 'update',
      _wpnonce: nonce,
      _wp_http_referer: referer,
      cart: {}
    }

    data.cart[key] = {
      qty: quantity
    }

    jQuery.post(referer, data, (postData) => {
      if (!$cartOverview.find('.cart-item').length) {
        jQuery('#cart').click()
      }

      const $shipping = jQuery(postData).find('#shipping')
      jQuery('#shipping').replaceWith($shipping)

      let totalNewPrice = parseFloat(jQuery('#shipping-cost').text())
      $cartOverview.find('.cart-item').each(function cartItem() {
        totalNewPrice += parseFloat(
          jQuery(this).find('.item-subtotal').find('span').attr('data-price')
        )
      })

      const $totalPrice = jQuery('#total-price')
      const $miniTotalPrice = jQuery('#mini-total-price')
      const totalCountUp = new CountUp($totalPrice.attr('id'), totalNewPrice)
      totalCountUp.start()
      const miniTotalCountUp = new CountUp($miniTotalPrice.attr('id'), totalNewPrice)
      miniTotalCountUp.start()
      $totalPrice.attr('data-price', totalNewPrice)
      $miniTotalPrice.attr('data-price', totalNewPrice)
    })
  })

  if (jQuery('div.element-form').length) {
    const $form = jQuery('div.element-form')
    const $inputField = jQuery('input')
    const height = $inputField.height()
    const $selects = $form.find('select')
    $selects.css('height', height)
  }

  function scrollRelatedProducts() {
    const $container = jQuery('.up-sells.upsells.products')
    const $arrowLeft = $container.find('.arrow-left')
    const $arrowRight = $container.find('.arrow-right')
    const $list = $container.find('.products')
    const containerWidth = $container.width()
    const $listElements = $list.find('li')
    const marginRight = parseInt($listElements.first().css('marginRight'), 10)
    const elementWidth = $list.find('li').first().outerWidth()

    $arrowLeft.on('click', () => {
      $list.animate({ scrollLeft: `-=${containerWidth + marginRight}` }, 500)
    })

    $arrowRight.on('click', () => {
      $list.animate({ scrollLeft: `+=${containerWidth + marginRight}` }, 500)
    })

    $list.on('scroll', () => {
      const scrollLeft = $list.scrollLeft()

      if (scrollLeft > 10) {
        $arrowLeft.show()
      } else {
        $arrowLeft.hide()
      }

      if (scrollLeft + $list.width() >= $listElements.length * elementWidth) {
        $arrowRight.hide()
      } else {
        $arrowRight.show()
      }
    })
  }

  scrollRelatedProducts()

  jQuery(
    '.product_cat-konfigurator-individuell #wooswipe .woocommerce-product-gallery__image a'
  ).on('click', (e) => {
    e.preventDefault()
    return false
  })
})
