import React from 'react'
import PropTypes from 'prop-types'

import Colors from '../data/colors.json'

const ColorPicker = (props) => {
  const { title, type, activeColor, handleColorChange } = props

  const onColorChange = (color) => {
    handleColorChange(color, type)
  }

  return (
    <div className='color-picker'>
      {title && <div className='color-picker__title'>{title}</div>}
      <div className='color-picker__colors'>
        {Object.entries(Colors).map(([key, value]) => {
          const classNames = activeColor === value ? 'color-button is-active-color' : 'color-button'
          return (
            <button
              type='button'
              className={classNames}
              key={key}
              style={{
                backgroundColor: value
              }}
              aria-label='Farbe wählen'
              value={value}
              onClick={(e) => onColorChange(e.target.value)}
            />
          )
        })}
      </div>
    </div>
  )
}

ColorPicker.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  activeColor: PropTypes.string,
  handleColorChange: PropTypes.func.isRequired
}

ColorPicker.defaultProps = {
  title: '',
  activeColor: ''
}

export default ColorPicker
