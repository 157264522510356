import React from 'react'
import PropTypes from 'prop-types'

const PatternPicker = (props) => {
  const { activePattern, handlePatternChange } = props

  const onPatternChange = (pattern) => {
    handlePatternChange(pattern)
  }

  return (
    <div className='pattern-picker'>
      <div className='pattern-picker__title'>Muster</div>
      <div className='pattern-picker__patterns'>
        <label htmlFor='pattern-halbraute'>
          <input
            type='radio'
            id='pattern-halbraute'
            name='pattern'
            value='Halbraute'
            checked={activePattern === 'Halbraute'}
            onChange={(e) => onPatternChange(e.target.value)}
          />
          <span>Halbraute</span>
        </label>
        <label htmlFor='pattern-zickzack'>
          <input
            type='radio'
            id='pattern-zickzack'
            name='pattern'
            value='Zickzack'
            checked={activePattern === 'Zickzack'}
            onChange={(e) => onPatternChange(e.target.value)}
          />
          <span>Zickzack</span>
        </label>
      </div>
    </div>
  )
}

PatternPicker.propTypes = {
  activePattern: PropTypes.string.isRequired,
  handlePatternChange: PropTypes.func.isRequired
}

export default PatternPicker
