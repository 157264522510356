import '../styles/main.styl'
import './jquery-functions'

import './CarpetConfigurator/App'

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', () => {
  // Audioplayer
  const audioPlayer = document.getElementById('audioplayer')
  if (audioPlayer.dataset.src) {
    const audio = new Audio(audioPlayer.dataset.src)
    audio.loop = true

    audioPlayer.addEventListener('click', (event) => {
      if (audioPlayer.classList.contains('is-playing')) {
        audioPlayer.classList.remove('is-playing')
        audio.pause()
      } else {
        audioPlayer.classList.add('is-playing')
        audio.play()
      }
      event.preventDefault()
    })
  }

  // Replace 'Teppich nach Wahl' link
  // Yep, this is super hacky ...
  const teppichCategoryLink = document.querySelector('#category-link-19 a')
  if (teppichCategoryLink) {
    teppichCategoryLink.setAttribute('href', '/teppiche-nach-mass/')
  }

  // Delete carpet
  const deleteCarpetButton = document.getElementById('myaccount-delete-carpet')

  const deleteCarpet = async (carpetID) => {
    const data = { carpetID }
    const response = await fetch('/wp-json/tessanda/delete-carpet/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const deletedCarpet = await response.json()
    if (deletedCarpet.success !== true) {
      console.error('Fehler beim Löschen des Teppichs')
    }
  }

  if (deleteCarpetButton) {
    const carpetID = deleteCarpetButton.dataset.carpet
    const carpetItem = deleteCarpetButton.closest('.myaccount-carpet-item')
    deleteCarpetButton.addEventListener('click', (event) => {
      deleteCarpet(carpetID)
      carpetItem.remove()
      event.preventDefault()
    })
  }
})
